import React from 'react';
import style from './details.module.scss';

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      product,
    } = { ...this.props };
    // 品牌
    let brand = '';
    if (product.brand_show) { // 不加这个判断直接赋值会报错
      brand = product.brand_show.brand_name;
    }
    return (
      <div>
        <div className={style.tableInfo}>
          <div>
            品牌：
            {brand}
          </div>
          <div>
            所属分类：
            {product.catName}
          </div>
          <div>
            商品编号：
            {product.sku}
          </div>
        </div>
        <div className={style.imgInfo}>
          {
            product.detail_images && product.detail_images.map((img) => ( // todo key
              <img style={{ width: img.image_width }} key={img.image_url} src={img.image_url} alt="" />
            ))
          }
        </div>
      </div>
    );
  }
}

export default Details;
