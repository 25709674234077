import React from 'react';
import style from './product.module.scss';
import setImg from '@/utils/imgs';
import {
  qrcode, productBaseInfo, specifications, goodsCommentList,
} from '@/config';
import common from '@/utils/common';
/* 商品价格等信息 */
import ProductInfo from './productInfo';
/* 商品详情 */
import Details from './details';
/* 商品规格 */
import Specifications from './specifications';
/* 商品评论 */
import Comments from './comments';
/* 侧边栏 */
import Top from '@/components/common/goodsList/common/goodClassify/common/top';
import Viewed from '@/components/common/goodsList/common/goodClassify/common/viewed';

/**
 * 涉及到的接口：
 * 商品详情
 * 生成wap商品二维码图片
 * 商品详情规格参数
 * 商品详情评论列表
 * */

/**
 * 条件显示详情/评价内容
 * */
function ShowContent(props) {
  const {
    curTab, product, productSpec, comments, page, score, pageChange,
  } = props;
  let Dom = <Details product={product} />;
  if (curTab === 1) { // 详细参数
    Dom = <Specifications productSpec={productSpec} />;
  } else if (curTab === 2) { // 商品评论
    Dom = <Comments comments={comments} page={page} score={score} pageChange={pageChange} />;
  }
  return Dom;
}

class Product extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById('root').scrollIntoView(true);
    const { match } = { ...this.props };
    console.log('match', match.params.id);
    this.state = {
      product: {},
      productSpec: [],
      imgInfo: [],
      imgIndex: {
        firstImg: '', // 第一张图集
        endImg: '', // 最后一张图集
      },
      curImg: setImg('lazyload.png'), // 当前图集(缩放图片时用)
      curImgNum: 0, // 当前图集索引(避免属性值相同的情况加的唯一值的判断)
      qrCode: 'https://www.sharkshopping.com/public/good_desc_imgs/qrc_data_360/9345861.png',
      score: {
        rate: '',
        total: 0,
      },
      comments: {},
      tabName: ['商品详情', '详细参数', '商品评论'],
      curTab: 0,
      // 接口传参用
      bn: match.params.id,
      goodsId: '',
      provinceCode: 110000, // 默认北京
      page: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  }

  /**
   * 生命周期函数
   * */
  // 渲染完dom之后执行
  componentDidMount() {
    this.productInit();
  }

  // 更新发生后立即调用
  componentDidUpdate() {
    const { match } = { ...this.props };
    const { bn } = { ...this.state };
    if (match.params.id !== bn) {
      this.productInit();
    }
  }

  /**
   * 生命周期函数结束
   * */
  productInit = async () => {
    const { match } = { ...this.props };
    this.setState({
      bn: match.params.id,
    });
    const {
      provinceCode,
    } = this.state;
    const bn = match.params.id;
    const detailsParams = {
      sku: bn,
      member_id: 58515,
      province_code: provinceCode,
      source: common.source,
      version: common.version,
      clientId: common.clientId,
    };
    this.getDetails(detailsParams);
    const specParams = {
      sku: bn,
      province_code: provinceCode,
      source: common.source,
      version: common.version,
      clientId: common.clientId,
    };
    this.getProductSpec(specParams);
    const codeParams = {
      bn,
      source: common.source,
      version: common.version,
      clientId: common.clientId,
    };
    this.getQrCode(codeParams);
  };

  /**
   * 接口请求数据
   * */
  // 请求商品详情
  getDetails = async (params) => {
    const response = await productBaseInfo(params);
    if (response.returndata) {
      if (response.status) {
        // 添加浏览记录
        const viewedList = JSON.parse(localStorage.getItem('viewedList')) || [];
        const bn = viewedList.map((val) => val.bn);
        const viewedProduct = response.returndata;
        if (!viewedProduct.bn) {
          viewedProduct.bn = viewedProduct.sku;
        }
        if (bn.indexOf(viewedProduct.bn) > -1) {
          viewedList.splice(bn.indexOf(viewedProduct.bn), 1);
        }
        viewedList.unshift(viewedProduct);
        if (viewedList.length >= 5) {
          viewedList.length = 5;
        }
        localStorage.setItem('viewedList', JSON.stringify(viewedList));

        const imgIndex = {
          firstImg: '',
          endImg: '',
        };
        if (response.returndata.images.length) {
          imgIndex.firstImg = response.returndata.images[0].image;
          imgIndex.endImg = response.returndata.images[response.returndata.images.length - 1].image;
        }

        const score = {
          rate: response.returndata.goods_comment_rate,
          total: response.returndata.comment.total,
        };
        // 增加唯一key
        const imgInfo = response.returndata.images.map((item, index) => ({
          ...item,
          key: index,
        }));
        this.setState({
          product: response.returndata,
          imgInfo,
          curImg: response.returndata.images[0] ? response.returndata.images[0].image : '',
          goodsId: response.returndata.goods_id,
          imgIndex,
          score,
        });
        this.getComments();
      } else {
        console.log(response.returndata.message);
      }
    }
  };

  // 商品详情规格参数
  getProductSpec = async (params) => {
    const response = await specifications(params);
    if (response.returndata) {
      if (response.status) {
        this.setState({
          productSpec: response.returndata,
        });
      } else {
        console.log(response.returndata.message);
      }
    }
  };

  // 分页
  pageChange = async (index) => {
    // 更新评论内容
    const { page } = this.state;
    page.pageNum = index; // 及时更新
    this.getComments();
  }

  // 商品详情评论列表
  getComments = async () => {
    const { goodsId, page } = this.state;
    const params = {
      id: goodsId,
      page_num: page.pageNum,
      page_size: page.pageSize,
      source: common.source,
      version: common.version,
      clientId: common.clientId,
    };
    const response = await goodsCommentList(params);
    if (response.returndata) {
      if (response.status) {
        this.setState({
          comments: response.returndata,
        });
      } else {
        console.log(response.returndata.message);
      }
    }
  };

  // 生成wap商品二维码图片
  getQrCode = async (params) => {
    const response = await qrcode(params);
    if (response.returndata) {
      if (response.status) {
        this.setState({
          qrCode: response.returndata.qrCode,
        });
      } else {
        console.log(response.returndata.message);
      }
    }
  };

  /**
   * 接口请求数据结束
   * */

  /**
   * 用户交互方法
   * */
  // 图片移动
  imgMove = (num) => {
    const { imgInfo, imgIndex } = this.state;
    const imgList = imgInfo;
    if (imgList.length > 4) {
      if (num) { // 向后
        if (imgList[3].image === imgIndex.endImg) {
          // 不能向后移动了
          console.log('不能向后移动了');
        } else {
          imgList.push(imgList.splice(0, 1)[0]);
        }
      } else if (!num) { // 向前
        if (imgList[0].image === imgIndex.firstImg) {
          // 不能向前移动了
          console.log('不能向前移动了');
        } else {
          imgList.unshift(imgList.splice(imgList.length - 1, 1)[0]);
        }
      }
      // 更新图片列表
      this.setState({
        imgInfo: imgList,
      });
    }
  }

  // 鼠标滑过小图
  imgMouseOver = (img) => {
    // 更新图片列表
    this.setState({
      curImg: img.image,
      curImgNum: img.key,
    });
  }

  // tab 切换内容区
  tabChange = (num) => {
    this.setState({
      curTab: num,
    });
  }

  /**
   * 用户交互方法结束
   * */

  render() {
    const {
      product, score, qrCode, imgInfo, imgIndex, curImg, curImgNum,
      tabName, curTab, productSpec, comments, page,
    } = this.state;
    return (
      <div className={style.container}>
        <div className={style.containerLeft}>
          {/* 商品信息 */}
          <ProductInfo
            product={product}
            score={score}
            imgInfo={imgInfo}
            imgIndex={imgIndex}
            curImg={curImg}
            curImgNum={curImgNum}
            qrCode={qrCode}
            imgMove={this.imgMove}
            imgMouseOver={this.imgMouseOver}
          />
          {/* tab */}
          <div className={style.detailsTab}>
            {
              tabName.map((name, index) => (
                <div
                  role="button"
                  tabIndex="0"
                  key={name}
                  className={`${curTab === index ? `${style.active}` : ''}`}
                  onClick={this.tabChange.bind(this, index)}
                >
                  {name}
                </div>
              ))
            }
          </div>
          {/* 内容区 */}
          <div className={style.content}>
            <ShowContent
              curTab={curTab}
              product={product}
              productSpec={productSpec}
              page={page}
              comments={comments}
              score={score}
              pageChange={this.pageChange}
            />
          </div>
        </div>
        <div className={style.containerRight}>
          <Top />
          <Viewed />
        </div>
      </div>

    );
  }
}

export default Product;
