import React from 'react';
import GoodsList from '../common/goodsList';
import APIURL from '@/config/APIURL';

class GoodDisplayList extends React.Component {
  constructor(...rest) {
    super(...rest);
    document.getElementById('root').scrollIntoView(true);
    this.state = {
      navigationObj: {
        happylife: APIURL.navigationHappyLife,
        fashion: APIURL.navigationFashion,
        loveBeauty: APIURL.navigationLoveBeauty,
        search: APIURL.searchGoods,
        classify: APIURL.categoryGoodslist,
        classifyFirst: APIURL.fristCategoryGoodsList,
        catList: APIURL.getBrandcatList,
      },
    };
  }

  render() {
    const { match, location } = { ...this.props };
    const { navigationObj } = this.state;
    return (
      <GoodsList
        url={navigationObj[match.params.url]}
        params={location.query}
        type={match.params.url}
      />
    );
  }
}

export default GoodDisplayList;
