import React from 'react';
import { withRouter } from 'react-router-dom';
import styleCss from './electronic.module.scss';
import setImg from '@/utils/imgs';
import RightAdv from '../rightAdvList';
import common from '@/utils/common';
import { invoiceDownload, searchLogistics } from '@/config';

class electronic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceCode: '', // 发票代码
      invoiceTitle: '', // 发票抬头
      invoiceNumber: '', // 发票号码
      message: '', // 查询信息
      isShow: 'none',
      pdfUrl: '', // pdf的下载地址
      inputText: '',
      wlList: [],
    };
  }

  // 当接收到新的道具或状态时，在渲染之前调用
  shouldComponentUpdate() {
    this.getInput();
    return true;
  }

  getInput = async () => {
    // 超出最大更新深度 不允许使用 setState
    this.state.invoiceCode = ''; // 发票代码
    this.state.invoiceTitle = ''; // 发票抬头
    this.state.invoiceNumber = ''; // 发票号码
    this.state.pdfUrl = '';
    this.state.inputText = '';
    this.state.message = '';
    this.state.isShow = 'none';
    this.state.wlList = []; // 物流详情
  };

  // 监听input 输入框
  inputChange = async (event) => {
    this.setState({
      inputText: event.target.value,
    });
  };

  // 下载发票的pdf功能
  dowloadPdf = async () => {
    const { pdfUrl } = this.state;
    if (pdfUrl === '') {
      return;
    }
    window.open(pdfUrl);
  };

  // 查询功能
  sendForm = async () => {
    const { location } = { ...this.props };
    this.setState({
      isShow: 'none',
      wlList: [],
      invoiceCode: '', // 发票代码
      invoiceTitle: '', // 发票抬头
      invoiceNumber: '', // 发票号码
      pdfUrl: '',
      message: '',
    });
    if (this.input.value === '') {
      let titleNr = '';
      if (location.state.type === 'fp') {
        titleNr = '请输入查询码';
      } else {
        titleNr = '请输入订单号';
      }

      this.setState({
        message: titleNr,
        isShow: 'block',
      });
      return;
    }
    this.handleClick(this.input.value.replace(/(^\s*)|(\s*$)/g, ''));
  };

  handleClick = async (data) => {
    this.setState({
      message: '提交中请稍候...',
      isShow: 'block',
    });
    const { location } = { ...this.props };
    const reuqest = () => {
      let params = {};
      let typeUrl;
      if (location.state.type === 'fp') {
        params = {
          source: common.source,
          version: common.version,
          clientId: common.clientId,
          invoiceCode: data,
        };
        typeUrl = invoiceDownload(params);
      }
      if (location.state.type === 'wl') {
        params = {
          source: common.source,
          version: common.version,
          clientId: common.clientId,
          order_id: data,
        };
        typeUrl = searchLogistics(params);
      }
      return typeUrl;
    };
    const response = await reuqest();
    if (response.returndata) {
      const invoiceInfo = response.returndata;
      console.log('invoiceInfo', invoiceInfo);
      if (location.state.type === 'wl') {
        this.setState({
          wlList: invoiceInfo,
          message: '成功',
        });
      } else if (location.state.type === 'fp') {
        if (response.returndata.status === 0) {
          this.setState({
            invoiceCode: invoiceInfo.invoiceInfo.yfphm,
            invoiceTitle: invoiceInfo.invoiceInfo.gmfmc,
            invoiceNumber: invoiceInfo.invoiceInfo.yfpdm,
            pdfUrl: invoiceInfo.invoiceInfo.downPath,
            message: '成功',
          });
        } else {
          this.setState({
            message: response.returndata.message,
            invoiceCode: '',
            invoiceTitle: '',
            invoiceNumber: '',
            pdfUrl: '',
          });
        }
      }
    } else {
      this.setState({
        message: response.message,
        isShow: 'block',
      });
    }
  };

  render() {
    const { location } = { ...this.props };
    const {
      inputText, isShow, message, invoiceCode, invoiceTitle, invoiceNumber, wlList,
    } = { ...this.state };
    const myStyle = {
      display: isShow,
    };
    return (
      <div className="contentWidth">
        <div className={styleCss.orderTableElectronic}>
          <div className={styleCss.qingtit}>
            <div className={styleCss.wlTitle}>
              聚鲨商城
              {location.state.type === 'fp' ? '电子发票' : '订单配送'}
查询
            </div>
            <div className={styleCss.wlBg}>
              <div>
                <input type="text" ref={(input) => { this.input = input; }} value={inputText} className={styleCss.orderInput} placeholder={location.state.type === 'fp' ? '请输入查询码' : '请输入订单号'} onChange={this.inputChange.bind(this)} />
                <p className={styleCss.orderBtn} onClick={this.sendForm} />
                <div style={myStyle}>
                  <div className={styleCss.orderMessage}>
                    <img src={setImg('warningImg.gif')} alt="警告图标" />
                    <p className={styleCss.orderTableText}>{ message }</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styleCss.orderBottomLine}>
              {
                location.state.type === 'fp'
                  ? (
                    <div>
                      <div className={styleCss.orderBottom}>
                        <div className={styleCss.companyName}>
                          发票代码:
                          {invoiceCode}
                        </div>
                        <div className={styleCss.orderNumber}>
                          发票抬头:
                          {invoiceTitle }
                        </div>
                      </div>
                      <div className={styleCss.orderBottom}>
                        <div className={styleCss.companyName}>
                          发票号码:
                          {invoiceNumber}
                        </div>
                        <div>
                          <img className={styleCss.downLoadImg} src={setImg('invoiceDownload.png')} onClick={() => { this.dowloadPdf(); }} alt="下载图标" />
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div>
                      {wlList && wlList.map((item) => (
                        <div key={item.invoice_no} className={styleCss.wlBox} style={myStyle}>
                          <div className={styleCss.orderMessage}>
                            <div className={styleCss.companyName}>
                              物流公司:
                              {item.invoice_cmp}
                            </div>
                            <div className={styleCss.orderNumber}>
                              物流单号:
                              {item.invoice_no}
                            </div>
                          </div>
                          <ul className={styleCss.wlUl}>
                            {
                              item.sends && item.sends.map((i) => (
                                <li key={i.time}>
                                  <p>{i.context}</p>
                                  <var>{i.time}</var>
                                </li>
                              ))
                            }
                          </ul>
                        </div>
                      ))}
                    </div>
                  )
              }
            </div>
          </div>
          <div className={styleCss.new}>
            <RightAdv />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(electronic);
