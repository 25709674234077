import React from 'react';
import '$assets/css/quillfont.css';
import '$assets/css/quillSnow.css';
import newsStyle from './news.module.scss';
import { hotGoodsList, arctcleInfo, leftarticleList } from '@/config';
import common from '@/utils/common';
import { formatDataStr } from '@/utils/date';

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveFlag: 0,
      leftTitle: [],
      goodsList: [],
      newsInfo: {},
      isGetGoods: false,
      params: {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
      },
    };
  }

  componentDidMount() {
    const { match } = { ...this.props };
    const { params } = { ...this.state };
    this.getGoodsList(params);
    if (match.params) {
      this.getNewsInfo(match.params.newsId);
      this.getleftarticleList(match.params.newsId);
    }
  }

  getGoodsList = async (params) => {
    // 此处调用接口
    const response = await hotGoodsList(params);
    if (response.returndata) {
      this.setState({ goodsList: response.returndata.list });
    }
  }

  getNewsInfo = async (newsId) => {
    // 此处调用接口
    const response = await arctcleInfo(newsId);
    if (response.returndata) {
      this.setState({ newsInfo: response.returndata });
      this.setState({ isGetGoods: true });
    }
  }

  getleftarticleList = async (newsId) => {
    const response = await leftarticleList(newsId);
    if (response.returndata && response.returndata.length > 0) {
      this.setState({ leftTitle: response.returndata.slice(0, 4) });
    }
  }

  toOtherNews = (number) => {
    this.getNewsInfo(number);
    this.getleftarticleList(number);
  }

  toDetail = (num) => {
    const { history } = { ...this.props };
    history.push({ pathname: `/product/${num}` });
    //  跳转商品详情
  }

  openGoods(flag) {
    this.setState({ ActiveFlag: flag });
  }

  render() {
    const formatDate = (time) => {
      if (time) {
        return formatDataStr(time, 'yyyy-MM-dd hh:mm');
      }
      return '';
    };
    const {
      leftTitle, goodsList, ActiveFlag, isGetGoods, newsInfo,
    } = { ...this.state };
    return (
      <div className="contentWidth">
        <div className={newsStyle.newsBox}>
          <div className={newsStyle.leftBox}>
            <div className={newsStyle.newsTitle}>
              {
                leftTitle.map((titleItem) => (
                  <div
                    className={newsStyle.titleItem}
                    key={titleItem.id}
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {}}
                    onClick={this.toOtherNews.bind(this, titleItem.id)}
                  >
                    {titleItem.title}
                  </div>
                ))
              }
            </div>
            <div className={newsStyle.rankList}>
              <div className={newsStyle.ranTitle}>
                热销排行榜
              </div>
              {
                goodsList.map((goodsItem, index) => (
                  <div
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {}}
                    className={newsStyle.goodsleftItem}
                    key={goodsItem.sku}
                    onClick={this.toDetail.bind(this, goodsItem.sku)}
                  >
                    <div
                      className={[ActiveFlag === index ? `${newsStyle.hide}` : '', `${newsStyle.goodsList}`].join(' ')}
                      onMouseEnter={() => { this.openGoods(index); }}
                    >
                      <i className={newsStyle.goodsIndex}>{index + 1}</i>
                      <p className={newsStyle.goodsName}>{goodsItem.name}</p>
                    </div>
                    <div className={[ActiveFlag === index ? `${newsStyle.active}` : '', `${newsStyle.goodsInfo}`].join(' ')}>
                      <div className={newsStyle.goodsPrice}>
                        <i className={newsStyle.goodsIndex}>{index + 1}</i>
                        {
                          goodsItem.price
                            ? (
                              <span className={newsStyle.nowPrice}>
                              &yen;
                                {goodsItem.price}
                              </span>
                            ) : ''
                        }
                        {
                          goodsItem.oldPrice
                            ? (
                              <span className={newsStyle.oldPrice}>
                              &yen;
                                {goodsItem.market_price}
                              </span>
                            ) : ''
                        }
                      </div>
                      <p className={newsStyle.goodsInfoName}>{goodsItem.name}</p>
                      <div className={newsStyle.imgBox}>
                        <img src={goodsItem.image} alt="商品图片" className={newsStyle.goodsImg} />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="rightFather">
            <div className={newsStyle.rightBox}>
              {
                isGetGoods
                  ? (
                    <div className={newsStyle.contentBox}>
                      <div className={newsStyle.articleTitle}>
                        {newsInfo.title}
                      </div>
                      <div className={newsStyle.articletDate}>
                        发布日期：
                        {formatDate(newsInfo.createTime)}
                      </div>
                      <div className={`${newsStyle.articlecontent} ${'ql-editor'}`}>
                        <span dangerouslySetInnerHTML={{ __html: newsInfo.content }} />
                      </div>
                    </div>
                  ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default News;
