import React from 'react';
import { articleActicleInfo } from '@/config';
import Top from '@/components/common/goodsList/common/goodClassify/common/top';
import style from './article.module.scss';
import '$assets/css/quillfont.css';
import '$assets/css/quillSnow.css';

class ArticleActicleInfo extends React.Component {
  constructor(props) {
    super(props);
    document.getElementById('root').scrollIntoView(true);
    this.state = {
      content: '',
      id: '',
      type: !this.getQueryString('type'),
    };
  }

  componentDidMount() {
    this.getArticleActicleInfo();
  }

  componentDidUpdate() {
    document.getElementById('root').scrollIntoView(true);
    const { match } = { ...this.props };
    const id = match.params.id.split('?')[0];
    const { id: stateId } = this.state;
    if (id !== stateId) {
      this.getArticleActicleInfo();
    }
  }

  getQueryString = (path) => {
    const reg = new RegExp(`(^|&)${path}=([^&]*)(&|$)`, 'i');
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return '';
  }

  // 获取帮助中心列表
  getArticleActicleInfo = async () => {
    const { match } = { ...this.props };
    const res = await articleActicleInfo({ id: match.params.id.split('?')[0] });
    this.setState({
      id: match.params.id.split('?')[0],
    });
    if (res.status) {
      this.setState({
        content: res.returndata.content,
      });
    }
  };

  render() {
    const { content, type } = this.state;
    return (
      <div className={`${!type ? style.pcStoreArticleContentPc : style.pcStoreArticleContent}`}>
        { !type && <Top /> }
        <div className={style.pcStoreArticleBox}>
          <div className={style.pcStoreArticle}>
            {/* <div className={style.pcStoreArticleTitle}> */}
              {/* <h2>文章标题</h2> */}
            {/* </div> */}
            <div className={style.pcStoreArticleDetail}>
              {/* <div className={style.pcStoreArtivleDate}>发布日期：2020年01月14日17:55:50</div> */}
              <div className={`${'ql-editor'}`} dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArticleActicleInfo;
