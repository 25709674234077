import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import setImg from '@/utils/imgs';
import style from './style.module.scss';

class GoodsList extends React.Component {
  constructor(...rest) {
    super(...rest);
    this.state = {
      parentProps: {},
    };
  }

  // 根据路由监听
  static getDerivedStateFromProps(props) {
    return {
      parentProps: props,
    };
  }

  setStorageGood = (key) => {
    // goods_id 返回数据格式不同
    // const viewedList = JSON.parse(localStorage.getItem('viewedList')) || [];
    // const bn = viewedList.map((val) => val.bn);
    const { parentProps } = this.state;
    // if (bn.indexOf(key.bn) > -1) {
    //   viewedList.splice(bn.indexOf(key.bn), 1);
    // }
    // viewedList.unshift(key);
    // if (viewedList.length >= 5) {
    //   viewedList.length = 5;
    // }
    // localStorage.setItem('viewedList', JSON.stringify(viewedList));
    parentProps.history.push({ pathname: `/product/${key.bn || key.sku}` });
  }

  render() {
    const { goodsList } = this.props;
    return (
      <div
        className={style.pcStoreGoodsListBox}
      >
        {
          goodsList.length > 0 ? goodsList && goodsList.map((val, key) => (
            <div
              className={style.pcStoreGoodsList}
              key={val.bn || val.sku}
              styling="link"
              tabIndex={key}
              role="button"
              onClick={this.setStorageGood.bind(this, val)}
            >
              <div className={style.pcStoreGoodsListImgbox}>
                <img src={val.image || setImg('lazyload.png')} alt="" />
              </div>
              <p>{val.name}</p>
              <span>
                ￥
                {val.price}
              </span>
            </div>
          ))
            : <div className={style.noShoppingText}>暂无商品哦～</div>
        }
      </div>
    );
  }
}

GoodsList.defaultProps = {
  goodsList: [],
};

GoodsList.propTypes = {
  goodsList: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(GoodsList);
