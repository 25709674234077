import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setImg from '@/utils/imgs';
import style from './fixed.module.scss';

class Fixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnBottom: false,
    };
  }

  componentDidMount() {
    window.onscroll = () => {
      if (window.scrollY > 180) {
        this.setState({
          returnBottom: true,
        });
      } else {
        this.setState({
          returnBottom: false,
        });
      }
    };
    return {
      NTKF_PARAM: {
        siteid: 'kf_9556', // 企业ID，为固定值，必填
        settingid: 'kf_9556_1494222161726', // 接待组ID，为固定值，必填
        uid: '', // 用户ID，未登录可以为空，但不能给null，uid赋予的值显示到小能客户端上
        uname: '', // 用户名，未登录可以为空，但不能给null，uname赋予的值显示到小能客户端上
        isvip: '1', // 是否为vip用户，0代表非会员，1代表会员，取值显示到小能客户端上
        userlevel: '', // 网站自定义会员级别，0-N，可根据选择判断，取值显示到小能客户端上
        // erpparam:'abc', erpparam为erp功能的扩展字段，可选，购买erp功能后用于erp功能集成
      },
    };
  }

  // 锚点链接
  scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      const anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
      // block:表示滚动到锚点的顶部或者底部，start/end
      // behavior:表示滚动的效果，auto/instant/smooth(滚动效果)
    }
  }

  // 调起客服窗口
  openChat = () => {
    window.NTKF.im_openInPageChat('kf_9556_1494222161726');
  }

  render() {
    const { returnBottom } = this.state;
    const { location: { pathname } } = this.props;
    const hideHeaderPath = ['/phonePage'];
    const hideHeader = hideHeaderPath.includes(pathname.trim());
    return (
      hideHeader ? null : (
        <div className={style.pcStorefixedBox}>
          {returnBottom && (
          <div
            styling="link"
            tabIndex={hideHeaderPath}
            role="button"
            className={style.returnBottom}
            onClick={() => this.scrollToAnchor('root')}
          >
          1
          </div>
          )}
          <div className={style.phone}>
            <img className={`${style.hover} ${style.phoneMsg}`} src={setImg('phoneouticon.png')} alt="" />
          </div>
          <img onClick={this.openChat} style={{ marginRight: '5px', cursor: 'pointer' }} src={setImg('qqicon.png')} alt="" />
        </div>
      )
    );
  }
}

Fixed.propTypes = {
  location: PropTypes.any.isRequired,
};

export default withRouter(Fixed);
