import React from 'react';
import style from './specifications.module.scss';

class Specifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      productSpec,
    } = { ...this.props };
    return (
      <div className={style.tableInfo}>
        {
          productSpec.map((item) => (
            <div key={item.group_name} className={style.group}>
              <div className={style.tableName}>{item.group_name}</div>
              {
                item.value_list.map((info) => (
                  <div key={info.value} className={style.tableContent}>
                    <div className={style.name}>{info.name}</div>
                    <div className={style.value}>{info.value}</div>
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    );
  }
}

export default Specifications;
