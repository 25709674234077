const { protocol } = window.location; // 获取当前用户访问的URL使用的协议
const { host } = window.location; // 获取当前用户访问URL主机信息
let yunBase; // 环球云根地址

if (host.indexOf('sharkshopping.com') > -1 || host.indexOf('ghs.net') > -1) {
  if(host.indexOf('win-') > -1) { // 微软云环境
    yunBase = 'win-api.ghs.net'; // 云商城正式环境授权模块根目录
  } else if (host.indexOf('www2.sharkshopping.com') > -1) { // 备机
    yunBase = 'api2.sharkshopping.com'; // 云商城正式环境授权模块根目录
  } else {
    yunBase = 'api.sharkshopping.com'; // 云商城正式环境授权模块根目录
  }
} else {
  yunBase = 'mall.api.test.globalgo.com.cn'; // 环球云测式环境授权模块根目录
}

// 接口的根地址
const baseURL = `${protocol}//${yunBase}/ec/api?method=`;

export default {
  invoiceDownload: `${baseURL}pc.invoice.query.download`,
  tvPrograms: `${baseURL}pc.tv.programs`,
  indexTopImg: `${baseURL}pc.indexTopImg`,
  advertInvoiceRight: `${baseURL}pc.advertInvoiceRight`,
  articleList: `${baseURL}article.latestInformation`,
  leftarticleList: `${baseURL}article.otherArticles`,
  homeExplosive: `${baseURL}pc.index.explosive`, // 首页聚爆款
  homeGlobalGo: `${baseURL}pc.indexGlobal`, // 首页环球购
  homeFashion: `${baseURL}pc.index.fashion`, // 首页尚潮流
  homeHappyLife: `${baseURL}pc.index.happyLife`, // 首页乐生活
  homeNewRelease: `${baseURL}b2c.newRelease.topNewRelease`, // 首页新品到
  flashSale: `${baseURL}second_kill`, // 首页限时抢购
  articleHelpCenter: `${baseURL}article.helpCenter`, // 帮助中心
  topImgJump: `${baseURL}pc.topImgJump`,
  searchGoodsHot: `${baseURL}b2c.goods2.search_goods_hot`, // 搜索页的搜索热词热词
  searchLogistics: `${baseURL}b2c.logistics.info2.search_logistics_pc`, // 物流查询
  qrcode: `${baseURL}pc.goods.qrcode`, // 生成图片二维码
  productBaseInfo: `${baseURL}b2c.product2.app_productBaseInfo`, // 商品详情
  specifications: `${baseURL}b2c.goodsdescss.specifications`, // 商品详情规格参数
  goodsCommentList: `${baseURL}b2c.product2.goods_comment_list`, // 商品详情评论列表
  searchGoods: `${baseURL}b2c.goods2.search_goods`, // 搜索功能接口
  navigationFashion: `${baseURL}pc.navigation.fashion`, // 导航尚潮流
  navigationHappyLife: `${baseURL}pc.navigation.happyLife`, // 导航乐生活
  navigationLoveBeauty: `${baseURL}pc.navigation.loveBeauty`, // 导航爱美丽
  indexTodayRecommend: `${baseURL}b2c.index.today_recommend`, // 热销排行榜
  categoryIndexList: `${baseURL}b2c.category.indexList`, // 逛一级分类
  categorySecondList: `${baseURL}b2c.category.secondList`, // 逛二级分类
  articleActicleInfo: `${baseURL}article.acticleInfo`, // 文章列表详情
  categoryGoodslist: `${baseURL}b2c.category.category_goods_list`, //  逛_二级分类
  fristCategoryGoodsList: `${baseURL}pc.fristCategoryGoodsList`, // 点击一级分类
  getBrandcatList: `${baseURL}b2c.category.get_brandcat_list`, // 点击推荐品牌
  articleListByCat: `${baseURL}pc.article.articleListByCat`, // pc文章管理-栏目下的文章列表
};
