import React from 'react';
import { withRouter } from 'react-router-dom';
import common from '@/utils/common';
import { indexTodayRecommend } from '@/config';
import style from '../style.module.scss';

class Top extends React.Component {
  constructor(...rest) {
    super(...rest);
    this.state = {
      dataList: [],
      hoverNowIndex: 0,
      parentProps: {},
      params: {
        ...common,
      },
    };
  }

  // 根据路由监听
  static getDerivedStateFromProps(props) {
    return {
      parentProps: props,
    };
  }

  componentDidMount() {
    this.getIndexTodayRecommend();
  }

  onMouseEnter(key) {
    const hoverNowIndex = key;
    this.setState({
      hoverNowIndex,
    });
  }

  onClickToDetail(val) {
    const { parentProps } = this.state;
    parentProps.history.push({ pathname: `/product/${val.sku}` });
  }

  getIndexTodayRecommend = async () => {
    const { params } = this.state;
    const res = await indexTodayRecommend(params);
    if (res.status) {
      this.setState({
        dataList: res.returndata.list,
      });
    }
  }

  render() {
    const { dataList, hoverNowIndex } = this.state;
    return (
      <div className={style.psStoreGoodsTop}>
        <div className={style.pcStoreGoodsClassifyTitle}>热销排行榜</div>
        <div className={style.pcStoreGoodsClassifyTopList}>
          {
            dataList.map((val, key) => (
              <div
                className={`${style.pcStoreGoodsClassifyTopOnce} ${hoverNowIndex === key ? style.hoverStatus : ''}`}
                onMouseEnter={this.onMouseEnter.bind(this, key)}
                key={val.sku}
                styling="link"
                tabIndex={key}
                role="button"
                onClick={this.onClickToDetail.bind(this, val)}
              >
                <span className={`${style.pcStoreGoodsTopNum} ${style.show}`}>{key + 1}</span>
                <p className={`${style.pcStoreTop} ${style.show}`}>{val.name}</p>
                <p className={style.hover}>
                  <span className={style.pcStoreGoodsTopNum}>{key + 1}</span>
                  <span className={style.price}>
                    ￥
                    {val.price}
                  </span>
                  <span className={style.mktprice}>
                    ￥
                    {val.market_price || val.price}
                  </span>
                </p>
                <div className={style.hover} style={{ width: '100%', cursor: 'pointer' }}>
                  <p className={style.hoverName}>{val.name}</p>
                  <div className={style.pcStoreGoodsTopImgbox}>
                    <img src={val.image} alt="" />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Top);
