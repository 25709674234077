import React from 'react';
import phonePage from './phonePage.module.scss';
import setImg from '@/utils/imgs';

class PhonePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      show: false, // show为true时回到顶部按钮显示，false时隐藏
    });

    // 将函数里的this指向绑定到当前组件，也就是组件ScrollToTop
    this.changeScrollTopShow = this.changeScrollTopShow.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  // 挂载事件监听
  componentDidMount() {
    window.addEventListener('scroll', this.changeScrollTopShow);
  }

  // 卸载事件监听
  componentWillUnmount() {
    window.removeEventListener('scroll', this.changeScrollTopShow);
  }

  // 控制show的状态从而控制回到顶部按钮的显示和隐藏
  changeScrollTopShow = async () => {
    if (window.pageYOffset < 400) {
      this.setState({
        show: false,
      });
    } else {
      this.setState({
        show: true,
      });
    }
  };

  // 添加动画效果
  scrollToTop = async () => {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 1);
  };

  render() {
    const { show } = this.state;
    return (
      <div className={phonePage.box}>
        <ul className={phonePage.ul}>
          <li>
            <img src={setImg('wap01.gif')} alt="图1" />
          </li>
          <li>
            <img src={setImg('wap02.gif')} alt="图1" />
          </li>
          <li>
            <img src={setImg('wap03.gif')} alt="图1" />
          </li>
          <li>
            <img src={setImg('wap04.gif')} alt="图1" />
          </li>
          <li className={phonePage.lastLi}>
            {
              show
              && <img src={setImg('mobile03.gif')} onClick={() => { this.scrollToTop(); }} alt="" />
            }
          </li>
        </ul>
      </div>
    );
  }
}
export default PhonePage;
