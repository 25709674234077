// import Home from '../components/homePage';
import News from '../components/news/index';
import newsStore from '../components/newsStore/index';
import Electronic from '../components/homePage/electronic';
import PhonePage from '../components/homePage/phonePage';
import HotGoodsList from '../components/pages/HotGoodsList';
import Product from '../components/product';
import GoodDisplayList from '../components/goodDisplayList';
import ArticleActicleInfo from '../components/common/article';
// PC静态改版
import PCStaticIndex from '../components/PCStatic/index/index';
import PCStaticBrand from '../components/PCStatic/brand/brand'; // 合作品牌页
import PCStaticMediaDateBase from '../components/PCStatic/mediaDateBase/mediaDateBase'; // 媒体资料库
import PCStaticAboutUs from '../components/PCStatic/aboutUs/aboutUs'; // 关于我们
import Partnership from '../components/PCStatic/Partnership/index';
import Business from '../components/PCStatic/Business/index';
import NewsInformation from '../components/PCStatic/NewsInformation/index';
import Responsibility from '../components/PCStatic/Responsibility/index';
import InvoiceQuery from '../components/PCStatic/InvoiceQuery/InvoiceQuery';//
const routes = [
  {
    path: '/',
    component: PCStaticIndex,
    exact: true,
  },
  {
    path: '/home',
    component: PCStaticIndex,
    exact: true,
  },
  {
    path: '/electronic',
    component: Electronic,
  },
  {
    path: '/newsStore',
    component: newsStore,
  },
  {
    path: '/hotGoodsList',
    component: HotGoodsList,
  },
  {
    path: '/phonePage',
    component: PhonePage,
  },
  {
    path: '/product/:id',
    component: Product,
  },
  {
    path: '/goodDisplayList/:url',
    component: GoodDisplayList,
  },
  {
    path: '/news/:newsId',
    component: News,
  },
  {
    path: '/articleInfo/:id',
    component: ArticleActicleInfo,
  },
  {
    path: '/PCStaticBrand',
    component: PCStaticBrand,
  },
  {
    path: '/PCStaticAboutUs',
    component: PCStaticAboutUs,
  },
  {
    path: '/partnership', // 合作关系-成为供应商
    component: Partnership,
  },
  {
    path: '/business', // 合作关系-商务合作
    component: Business,
  },
  {
    path: '/newsInformation', // 新闻动态
    component: NewsInformation,
  },
  {
    path: '/responsibility', // 社会责任
    component: Responsibility,
  },
  {
    path: '/PCStaticMediaDateBase',
    component: PCStaticMediaDateBase,
  },
  {
    path: '/InvoiceQuery',
    component: InvoiceQuery,
  }
  // {
  //   path: "/user",
  //   component: User,
  //   routes:[   /*嵌套路由*/
  //     {
  //       path: "/user/",
  //       component: ...
  //     },
  //     {
  //       path: "/user/add",
  //       component: ...
  //     },
  //     {
  //       path: "/user/edit",
  //       component: ...
  //     }
  //   ]
  // }
];
// const reg = /Android|webOS|iPhone|iPod|BlackBerry/i;
// const flag = reg.test(navigator.userAgent);
//
// if (flag) {
//   routes[0].component = InvoiceQuery;
// }
export default routes;
