import React from 'react';
import newsStyle from './newsStore.module.scss';
import Pagination from '../common/pagination';
import common from '@/utils/common';
import { hotGoodsList, leftarticleList, articleList } from '@/config';
import { formatDataStr } from '@/utils/date';

class newsStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
      },
      newsParams: {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
        pageIndex: 1,
        pageSize: 15,
      },
      ActiveFlag: 0,
      leftTitle: [],
      goodsList: [],
      newsList: [],
      isGetGoods: false,
      total: 0,
      firstNewsId: '',
    };
  }

  componentDidMount() {
    const { params, newsParams } = { ...this.state };
    this.getGoodsList(params);
    this.getNewsList(newsParams);
  }

  getGoodsList = async (params) => {
    // 此处调用接口
    const response = await hotGoodsList(params);
    if (response.returndata) {
      this.setState({
        goodsList: response.returndata.list,
      });
    }
  }

  getleftarticleList = async (newsId, type) => {
    const response = await leftarticleList(newsId, type);
    if (response.returndata && response.returndata.length > 0) {
      this.setState({ leftTitle: response.returndata.slice(0, 4) });
    }
  }

  toOtherNews = (number) => {
    this.getleftarticleList(number);
  }

  toDetail = (newsId) => {
    const { history } = { ...this.props };
    history.push({ pathname: `/news/${newsId}` });
    //  跳转文章详情
  }

  NumChange = (pageIndex) => {
    const { newsParams } = { ...this.state };
    this.setState({
      newsParams: Object.assign(newsParams, { pageIndex }),
    });
    this.getNewsList(newsParams);
  }

  getNewsList = async (newsParams) => {
    const response = await articleList(newsParams);
    if (response.returndata) {
      this.setState({
        newsList: response.returndata.articleInfo.articleList,
        firstNewsId: response.returndata.articleInfo.articleList[0].id,
        isGetGoods: true,
        total: response.returndata.totalCount,
      });
      const { firstNewsId } = { ...this.state };
      this.getleftarticleList(firstNewsId, 1);
    }
  }

  openGoods(flag) {
    this.setState({ ActiveFlag: flag });
  }

  render() {
    const formatDate = (time) => {
      if (time) {
        return formatDataStr(time, 'yyyy-MM-dd hh:mm');
      }
      return '';
    };
    const {
      leftTitle, goodsList, ActiveFlag, isGetGoods, newsList, total, newsParams,
    } = { ...this.state };
    return (
      <div className="contentWidth">
        <div className={newsStyle.newsBox}>
          <div className={newsStyle.leftBox}>
            <div className={newsStyle.newsTitle}>
              {
                leftTitle.map((titleItem) => (
                  <div
                    className={newsStyle.titleItem}
                    key={titleItem.id}
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {}}
                    onClick={this.toOtherNews.bind(this, titleItem.id)}
                  >
                    {titleItem.title}
                  </div>
                ))
              }
            </div>
            <div className={newsStyle.rankList}>
              <div className={newsStyle.ranTitle}>
                热销排行榜
              </div>
              {
                goodsList.map((goodsItem, index) => (
                  <div
                    role="button"
                    tabIndex="0"
                    onKeyDown={() => {}}
                    className={newsStyle.goodsleftItem}
                    key={goodsItem.sku}
                    onClick={this.toDetail.bind(this, goodsItem.sku)}
                  >
                    <div
                      className={[ActiveFlag === index ? `${newsStyle.hide}` : '', `${newsStyle.goodsList}`].join(' ')}
                      onMouseEnter={() => { this.openGoods(index); }}
                    >
                      <i className={newsStyle.goodsIndex}>{index + 1}</i>
                      <p className={newsStyle.goodsName}>{goodsItem.name}</p>
                    </div>
                    <div className={[ActiveFlag === index ? `${newsStyle.active}` : '', `${newsStyle.goodsInfo}`].join(' ')}>
                      <div className={newsStyle.goodsPrice}>
                        <i className={newsStyle.goodsIndex}>{index + 1}</i>
                        {
                          goodsItem.price
                            ? (
                              <span className={newsStyle.nowPrice}>
                              &yen;
                                {goodsItem.price}
                              </span>
                            ) : ''
                        }
                        {
                          goodsItem.oldPrice
                            ? (
                              <span className={newsStyle.oldPrice}>
                              &yen;
                                {goodsItem.market_price}
                              </span>
                            ) : ''
                        }
                      </div>
                      <p className={newsStyle.goodsInfoName}>{goodsItem.name}</p>
                      <div className={newsStyle.imgBox}>
                        <img src={goodsItem.image} alt="商品图片" className={newsStyle.goodsImg} />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="rightFather">
            {
              isGetGoods
                ? (
                  <div className={newsStyle.rightBox}>
                    <div className={newsStyle.contentBox}>
                      {newsList.map((news) => (
                        <div
                          key={news.id}
                          role="button"
                          tabIndex="0"
                          onKeyDown={() => {}}
                          className={newsStyle.arctileitem}
                          onClick={this.toDetail.bind(this, news.id)}
                        >
                          <div className={newsStyle.newstitle}>
                            {news.title}
                          </div>
                          <div className={newsStyle.newsDate}>
                            [
                            {formatDate(news.createTime)}
                            ]
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={newsStyle.pagelist}>
                      <Pagination
                        onChange={this.NumChange}
                        defaultPageSize={newsParams.pageSize}
                        current={newsParams.pageIndex}
                        total={total}
                      />
                    </div>
                  </div>
                ) : ''
            }
          </div>
        </div>
      </div>
    );
  }
}

export default newsStore;
