import APIURL from './APIURL';
import ajax from './ajax';

export const articleList = (params) => ajax(APIURL.articleList, params, 'get');
export const leftarticleList = (articeId, type) => ajax(APIURL.leftarticleList, { articeId, type }, 'get');
export const arctcleInfo = (id) => ajax(APIURL.articleActicleInfo, { id }, 'get');
export const hotGoodsList = (params) => ajax(APIURL.indexTodayRecommend, params, 'get');
export const tvPrograms = (tvId) => ajax(APIURL.tvPrograms, { tvId }, 'get');
export const tvPro = (id) => ajax(APIURL.tvPro, { id }, 'get');
export const invoiceDownload = (params) => ajax(APIURL.invoiceDownload, params, 'get');
export const searchLogistics = (params) => ajax(APIURL.searchLogistics, params, 'get');
export const searchGoodsHot = (params) => ajax(APIURL.searchGoodsHot, params, 'get');
export const indexTopImg = (params) => ajax(APIURL.indexTopImg, params, 'get');
export const advertInvoiceRight = (params) => ajax(APIURL.advertInvoiceRight, params, 'get');
export const articleHelpCenter = () => ajax(APIURL.articleHelpCenter, {}, 'get');
export const topImgJump = (params) => ajax(APIURL.topImgJump, params, 'get');
export const indexList = (params) => ajax(APIURL.categoryIndexList, params, 'get');
export const secondList = (params) => ajax(APIURL.categorySecondList, params, 'get');
export const qrcode = (params) => ajax(APIURL.qrcode, params, 'get');
export const productBaseInfo = (params) => ajax(APIURL.productBaseInfo, params, 'get');
export const specifications = (params) => ajax(APIURL.specifications, params, 'get');
export const goodsCommentList = (params) => ajax(APIURL.goodsCommentList, params, 'get');
export const navigationFashion = () => ajax(APIURL.navigationHappylife, {}, 'get');
export const navigationHappylife = () => ajax(APIURL.navigationHappylife, {}, 'get');
export const indexTodayRecommend = (params) => ajax(APIURL.indexTodayRecommend, { ...params }, 'get');
export const categoryIndexList = () => ajax(APIURL.categoryIndexList, {}, 'get');
export const categorySecondList = (params) => ajax(APIURL.categorySecondList, { ...params }, 'get');
export const articleActicleInfo = (params) => ajax(APIURL.articleActicleInfo, { ...params }, 'get');
export const fristCategoryGoodsList = (params) => ajax(APIURL.fristCategoryGoodsList, { ...params }, 'get');

export const articleListByCat = (params) => ajax(APIURL.articleListByCat, { ...params }, 'get');
