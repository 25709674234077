/**
 * 放大镜插件
 * @param imageUrl 图片路径 必填
 * @param widthImg 图片宽度
 * @param heightImg 图片高度
 * @param widthBlock 块宽度
 * @param heightBlock 块高度
 * @param distanceImg 放大图片容器距离图片容器的距离
 * @param scale 放大倍数
 * * */
import React from 'react';
import PropTypes from 'prop-types';
import style from './imageMagnifier.module.scss';

class ImageMagnifier extends React.Component {
  constructor(...rest) {
    super(...rest);
    const {
      widthImg, heightImg, widthBlock, heightBlock, scale, imageUrl, distanceImg,
    } = this.props;
    const distanceImgD = Number(distanceImg) + Number(widthImg);
    this.state = {
      imageUrl,
      distanceImg: distanceImgD,
      blockFlag: false,
      offsetData: {
        maxX: widthImg - widthBlock / 2,
        minX: widthBlock / 2,
        maxY: heightImg - widthBlock / 2,
        minY: widthBlock / 2,
        scale,
      },
      paramsImg: { // 设置宽高
        width: `${widthImg}px`,
        height: `${heightImg}px`,
      },
      blockStyle: { // 小块样式
        width: `${widthBlock}px`,
        height: `${heightBlock}px`,
        left: '0px',
        top: '0px',
      },
      scaleImgStyle: { // 放大图片样式
        width: '100%',
        height: 'auto',
        position: 'absolute',
        top: '0px',
        left: '0px',
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
      },
    };
  }

  /**
   * 生命周期函数
   */
  // 当接收到新的道具或状态时，在渲染之前调用
  shouldComponentUpdate(nextProps) {
    this.updataImg(nextProps);
    return true;
  }

  // 更新图片
  updataImg = (props) => {
    // 超出最大更新深度 不允许使用 setState
    this.state.imageUrl = props.imageUrl;
  };

  // 鼠标移入
  mouseEnter = () => {
    this.setState({
      blockFlag: true,
    });
  };

  // 鼠标移除
  mouseLeave = () => {
    this.setState({
      blockFlag: false,
    });
  };

  // 鼠标移动
  mouseMove = (e) => {
    const event = e.nativeEvent;
    this.calculationPosition(event.offsetX, event.offsetY);
  };

  // 计算移动位置
  calculationPosition(offsetx, offsety) {
    const { blockStyle, scaleImgStyle, offsetData } = this.state;
    const blockStylePos = JSON.parse(JSON.stringify(blockStyle));
    const scaleImgStylePos = JSON.parse(JSON.stringify(scaleImgStyle));
    const {
      minX, minY, maxX, maxY, scale,
    } = offsetData;

    let offsetX = offsetx;
    let offsetY = offsety;

    if (offsetX < minX) {
      offsetX = minX;
    }
    if (offsetX > maxX) {
      offsetX = maxX;
    }
    if (offsetY < minY) {
      offsetY = minY;
    }
    if (offsetY > maxY) {
      offsetY = maxY;
    }
    // 同步位置
    blockStylePos.left = `${parseFloat(offsetX - minX)}px`;
    blockStylePos.top = `${parseFloat(offsetY - minY)}px`;

    scaleImgStylePos.left = `${parseFloat(-(offsetX - minX) * scale)}px`;
    scaleImgStylePos.top = `${parseFloat(-(offsetY - minY) * scale)}px`;

    this.setState({
      blockStyle: blockStylePos,
      scaleImgStyle: scaleImgStylePos,
    });
  }

  render() {
    const {
      blockFlag, blockStyle, scaleImgStyle, paramsImg, distanceImg, imageUrl,
    } = this.state;
    return (
      <div className={style.imageScaleContent} style={paramsImg}>
        <img className={style.imageScaleImg} alt="放大图片" src={imageUrl} />
        <div
          className={style.imageScaleMoveBox}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          onMouseMove={this.mouseMove}
        />
        {blockFlag && <div className={style.imageScaleBlock} style={blockStyle} />}
        {blockFlag && (
        <div className={style.imageScaleBox} style={{ ...paramsImg, left: distanceImg }}>
          <img style={scaleImgStyle} alt="放大图片" src={imageUrl} />
        </div>
        )}
      </div>
    );
  }
}

ImageMagnifier.defaultProps = {
  widthImg: 400,
  heightImg: 400,
  widthBlock: 100,
  heightBlock: 100,
  distanceImg: 20,
  scale: 4,
};

ImageMagnifier.propTypes = {
  widthImg: PropTypes.number,
  heightImg: PropTypes.number,
  widthBlock: PropTypes.number,
  heightBlock: PropTypes.number,
  distanceImg: PropTypes.number,
  scale: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
};

export default ImageMagnifier;
