import React from 'react';
import { withRouter } from 'react-router-dom';
import rightAdvStyle from './rightAdvList.module.scss';
import { advertInvoiceRight } from '@/config';
import common from '../../../utils/common';

class RightAdv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getList: [],
      params: {
        source: common.source,
        version: common.version,
        clientId: common.clientId,
      },
    };
  }

  // 渲染完dom之后执行这个生命周期
  componentDidMount() {
    const { params } = { ...this.state };
    this.getData(params);
  }

  getData = async (params) => {
    const response = await advertInvoiceRight(params);
    if (response.returndata) {
      if (response.status) {
        this.setState({
          getList: response.returndata,
        });
      } else {
        console.log(response.returndata.message);
      }
    }
  };

  btnFn(item) {
    const { history } = { ...this.props };
    history.push({ pathname: `/product/${item.bn}` });
  }

  render() {
    const { getList } = this.state;
    return (
      <div className={rightAdvStyle.news}>
        {
          getList.map((item) => (
            <div className={rightAdvStyle.newsPic} key={item.image}>
              <img src={item.image} alt="聚鲨商品图片" onClick={() => { this.btnFn(item); }} />
            </div>
          ))
        }
      </div>
    );
  }
}
export default withRouter(RightAdv);
