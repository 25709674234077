import React from 'react';
import { withRouter } from 'react-router-dom';
import style from '../style.module.scss';

class Viewed extends React.Component {
  constructor(...rest) {
    super(...rest);
    const viewedList = JSON.parse(localStorage.getItem('viewedList')) || [];
    this.state = {
      viewedList,
      parentProps: {},
    };
  }

  // 根据路由监听
  static getDerivedStateFromProps(props) {
    return {
      parentProps: props,
    };
  }

  onClickToDetail(val) {
    const { parentProps } = this.state;
    parentProps.history.push({ pathname: `/product/${val.bn}` });
  }

  render() {
    const { viewedList } = this.state;
    return (
      <div className={style.psStoreGoodsViewed}>
        <div className={style.pcStoreGoodsClassifyTitle}>浏览过的商品</div>
        <div className={style.psStoreGoodsViewedBox}>
          {
            viewedList.map((val) => (
              <div
                className={style.psStoreGoodsViewedMsg}
                key={val.bn}
                styling="link"
                tabIndex={val.bn}
                role="button"
                onClick={this.onClickToDetail.bind(this, val)}
              >
                <div className={style.psStoreGoodsViewedImgbox}>
                  <img src={val.image || val.img_account} alt="" />
                </div>
                <div className={style.psStoreGoodsViewedWords}>
                  <p>{val.name}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    );
  }
}

export default withRouter(Viewed);
