import React from 'react';
import { withRouter } from 'react-router-dom';
import { topImgJump } from '@/config';
import style from './HotGoodsList.module.scss';
import common from '@/utils/common';
import setImg from '@/utils/imgs';

class HotGoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topImgJumpData: {},
      goodsList: [],
      img: {},
      totalCount: 0,
      totalHeight: 0,
      winHeight: window.innerHeight,
      showMore: false,
      pullUpData: '好厉害，您已经看完了所有商品~',
      isAjaxSending: false, // 是否正在请求
      lazyLoadImg: setImg('lazyload.png'), // 默认图
      params: {
        pageIndex: 1,
        pageSize: 20,
        source: common.source,
        version: common.version,
        clientId: common.clientId,
      },
    };
  }

  componentDidMount() {
    const { params } = { ...this.state };
    this.getTopImgJump(params);
    window.addEventListener('scroll', this.addOtherGoods);
    window.addEventListener('load', this.getHeight);
  }

  // 销毁
  componentWillUnmount() {
    window.removeEventListener('scroll', this.addOtherGoods);
    window.removeEventListener('load', this.getHeight);
  }

  // 获取首页头图跳转数据
  getTopImgJump = async (type) => {
    const {
      isAjaxSending,
      goodsList,
      params,
    } = this.state;
    if (isAjaxSending) {
      return;
    }
    if (type === 'add') { // 防止在发请求的过程中触发addOtherGoods导致页数加一
      const index = params.pageIndex;
      this.setState({
        params: Object.assign(params, { pageIndex: index + 1 }),
      });
    }
    this.setState({
      isAjaxSending: true,
    });
    const res = await topImgJump(params);
    if (res.status && res.status !== 'false') {
      this.setState({
        isAjaxSending: false,
      });
      const data = res.returndata;
      const list = [...goodsList, ...data.goodsList];
      this.setState({
        topImgJumpData: data,
        img: data.img,
        goodsList: list,
        totalCount: (+data.totalPage),
      });
    } else {
      this.setState({
        isAjaxSending: false,
      });
    }
  }

  getHeight= () => { // 获取高度
    this.setState({
      totalHeight: document.body.scrollHeight || document.documentElement.scrollHeight,
    });
  }

  // 跳转到商品详情
  toDetail = (sku) => {
    const { history } = { ...this.props };
    history.push({ pathname: `/product/${sku}` });
  }

  addOtherGoods = () => {
    const {
      totalCount,
      goodsList,
      totalHeight,
      winHeight,
    } = this.state;
    const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
    if (goodsList && goodsList.length < totalCount
        && totalHeight <= (winHeight + scrolled + 52)) {
      this.setState({
        pullUpData: '加载更多',
        showMore: true,
      });
      this.getTopImgJump('add');
    } else {
      this.setState({
        pullUpData: '好厉害，您已经看完了所有商品~',
        showMore: false,
      });
    }
  }

  render() {
    const {
      goodsList, img, pullUpData, lazyLoadImg,
    } = { ...this.state };
    return (
      <div className={style.activityPage}>
        <div className={style.advBanner}>
          <img src={img.url} alt="" />
        </div>
        <div className={style.goodsList}>
          <div className={style.content}>
            {
              goodsList && goodsList.length && goodsList.map((goods) => (
                <div
                  role="button"
                  tabIndex="0"
                  onClick={this.toDetail.bind(this, goods.bn)}
                  className={style.goods}
                  key={goods.bn}
                >
                  <div className={style.goodsImg}>
                    <img src={goods.image || lazyLoadImg} alt={goods.name} />
                  </div>
                  <div className={style.goodsInfo}>
                    <p className={style.name}>{goods.name}</p>
                    <div className={style.operation}>
                      <p className={style.price}>
                        <span>&yen;</span>
                        {goods.price}
                      </p>
                      <span className={style.buyNow}>立即抢购</span>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <p className={style.pullup}>{pullUpData}</p>
      </div>
    );
  }
}

export default withRouter(HotGoodsList);
