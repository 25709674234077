import React from 'react';
import style from './productInfo.module.scss';
import ImageMagnifier from '@/components/common/imageMagnifier';

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // 图片切换
  imgMove = (num) => {
    const { imgMove } = { ...this.props };
    imgMove(num);
  }

  // 鼠标滑过
  imgMouseOver = (ind) => {
    const { imgMouseOver } = { ...this.props };
    imgMouseOver(ind);
  }

  render() {
    const {
      product, qrCode, imgInfo, imgIndex, curImg, curImgNum, score,
    } = { ...this.props };
    return (
      <div>
        <h1 className={style.productName}>{product.name}</h1>
        <h4 className={style.productDesc}>{product.brief}</h4>
        <div className={style.productInfo}>
          <div className={style.productImgArea}>
            <div className={style.productImg}>
              <ImageMagnifier
                imageUrl={curImg}
                widthImg={300}
                heightImg={300}
                widthBlock={150}
                heightBlock={150}
                distanceImg={200}
                scale={2}
              />
            </div>
            <div className={style.productImgGroup}>
              <div
                role="button"
                tabIndex="0"
                className={[`${style.filp}`, `${style.prev}`, imgInfo[0] && imgInfo[0].image === imgIndex.firstImg ? `${style.over}` : ''].join(' ')}
                onClick={this.imgMove.bind(this, 0)}
              >
                <span className={style.posCenter} />
              </div>
              <div className={style.imgList}>
                {
                  imgInfo && imgInfo.map((img) => (
                    <div
                      className={`${img.key === curImgNum ? `${style.active}` : ''}`}
                      key={img.key}
                      onMouseOver={this.imgMouseOver.bind(this, img)}
                      onFocus={() => {}}
                    >
                      <div className={`${style.arrow} ${style.arrowTop}`} />
                      <img className={style.productEverImg} src={img.image} alt="商品图" />
                    </div>
                  ))
                }
              </div>
              {/* 只显示四个 */}
              <div
                role="button"
                tabIndex="0"
                className={`${style.filp} ${style.next} ${imgInfo.length < 4 || imgInfo[3].image === imgIndex.endImg ? `${style.over}` : ''}`}
                onClick={this.imgMove.bind(this, 1)}
              >
                <span className={style.posCenter} />
              </div>
            </div>
          </div>
          <div className={style.productContent}>
            <div>
              销售价：
              <span>
                ¥
                {product.price}
              </span>
            </div>
            <div>
              好评率：
              {score.rate ? score.rate : '暂无评分'}
              {`(共${score.total}人评论)`}
            </div>
            <div>
              货品编号：
              {product.sku}
            </div>
            <img src={qrCode} alt="二维码" />
            <div>请用手机扫描此二维码下单</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductInfo;
