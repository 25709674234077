import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { categorySecondList } from '@/config';
import common from '@/utils/common';

import style from '../style.module.scss';

class Classify extends React.Component {
  constructor(...rest) {
    super(...rest);
    const { fid } = this.props;
    this.state = {
      dataList: [],
      catList: [],
      fid,
      parentProps: {},
      params: {
        ...common,
      },
    };
  }

  componentDidMount() {
    // this.getCategorySecondList();
  }

  // 根据路由监听
  static getDerivedStateFromProps(props) {
    return {
      parentProps: props,
    };
  }

  componentDidUpdate() {
    const { fid, catList } = this.props;
    const { fid: stateFid, catList: stateCatList } = this.state;
    if (fid) {
      if (fid !== stateFid) {
        this.getCategorySecondList();
      }
    } else if (catList.length > 0 && JSON.stringify(catList) !== JSON.stringify(stateCatList)) {
      this.setCatList();
    }
  }

  setCatList = () => {
    const { catList } = this.props;
    this.setState({
      catList,
    });
  }

  getCategorySecondList = async () => {
    const { fid } = this.props;
    this.setState({
      fid,
    });
    const { params } = this.state;
    const res = await categorySecondList({ type: 0, f_id: fid, ...params });
    if (res.status) {
      this.setState({
        dataList: res.returndata.list,
      });
    }
  }

  toClassify = (id) => {
    const { parentProps } = this.state;
    const query = {
      member_id: 0,
      category_id: id,
      city_num: 110100,
      orderBy_id: 3,
      page_num: 1,
      page_size: 12,
      type: 3,
      version: common.version,
    };
    parentProps.history.push({ pathname: '/goodDisplayList/classify', query });
  }

  render() {
    const { dataList, catList } = this.state;
    const { name } = this.props;
    return (
      <div className={style.pcStoreGoodsClassify}>
        <div className={style.pcStoreGoodsClassifyTitle}>商品分类</div>
        <div className={style.pcStoreGoodsClassifyDetail}>
          {!!name && (<div className={style.pcStoreGoodsClassifyDetailTitle}>{name}</div>)}
          <div className={style.pcStoreGoodsClassifyDetailBox}>
            {!!dataList.length && (
              dataList.map((val) => (
                val.list.map((v) => (
                  <p
                    onClick={() => { this.toClassify(v.id); }}
                    title={v.name}
                    key={v.id}
                  >
                    {v.name}
                  </p>
                ))
              ))
            )}
            {!!catList.length && (
              catList.map((v) => (
                <p
                  onClick={() => { this.toClassify(v.cat_id); }}
                  title={v.name}
                  key={v.cat_id}
                >
                  {v.name}
                </p>
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

Classify.defaultProps = {
  fid: '',
  name: '',
  catList: [],
};

Classify.propTypes = {
  fid: PropTypes.string,
  name: PropTypes.string,
  catList: PropTypes.arrayOf(PropTypes.object),
};

export default withRouter(Classify);
