import React from 'react';
import PropTypes from 'prop-types';
import Classify from './common/classify';
import Top from './common/top';
import Viewed from './common/viewed';
import style from './style.module.scss';

class GoodsClassify extends React.Component {
  constructor(...rest) {
    super(...rest);
    this.state = {};
  }

  render() {
    const { fid, name, catList } = this.props;
    return (
      <div className={style.pcStoreGoodsClassifyBox}>
        <Classify fid={fid} name={name} catList={catList} />
        <Top />
        <Viewed />
      </div>
    );
  }
}

GoodsClassify.defaultProps = {
  fid: '',
  name: '',
  catList: [],
};

GoodsClassify.propTypes = {
  fid: PropTypes.string,
  name: PropTypes.string,
  catList: PropTypes.arrayOf(PropTypes.object),
};
export default GoodsClassify;
