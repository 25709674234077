import React from 'react';
import style from './comments.module.scss';
import Pagination from '@/components/common/pagination';

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * 分页跳转
   * */
  pageChange = (index) => { // 正确
    const { pageChange } = { ...this.props };
    pageChange(index);
    console.log('index', index)
  }

  render() {
    const {
      score, page, comments,
    } = { ...this.props };
    return (
      <div className={style.container}>
        <div className={style.commentHead}>
          好评率：
          {score.rate ? score.rate : '暂无评分'}
          {`(共${score.total}人评论)`}
        </div>
        <hr />
        <br />
        {
          comments.content && comments.content.map((item) => (
            <div key={item.order_id} className={style.commentItem}>
              <div className={style.commentLeft}>
                <img className={style.commentatorImg} src={item.member_image} alt="头像" />
                <div className={style.commentatorName}>{item.name}</div>
              </div>
              <div className={style.commentRight}>
                {
                  item.imgs && item.imgs.map((img) => (
                    <img key={img} src={img} alt="评论图" />
                  ))
                }
                <div className={style.commentText}>{item.comment}</div>
              </div>
            </div>
          ))
        }
        <div className={style.pageArea}>
          <Pagination
            current={page.pageNum}
            total={comments.total ? comments.total : 0}
            pageSize={page.pageSize}
            onChange={this.pageChange}
          />
        </div>
      </div>
    );
  }
}

export default Comments;
