import React from 'react';
import PropTypes from 'prop-types';
import style from './goodsList.module.scss';
import GoodList from './common/goodList';
import GoodClassify from './common/goodClassify';
import PagePaginaton from '../pagination';
import ajax from '@/config/ajax';

const WHITEARRAY = ['happylife', 'fashion', 'loveBeauty', 'classifyFirst']; // 一进来就刷新白名单
class GoodDisplayList extends React.Component {
  constructor(...rest) {
    super(...rest);
    const { url, params, type } = this.props;
    this.state = {
      params,
      url,
      type,
      catList: [],
      goodsList: [],
      id: '',
      name: '',
      pageNum: 1,
      total: '',
    };
  }

  componentDidMount() {
    // const { type } = this.state;
    // if (WHITEARRAY.includes(type)) {
    this.getGoodsList();
    // }
  }

  componentDidUpdate() {
    const { type, params } = this.props;
    const { type: stateType, params: stateParams } = this.state;
    if (type !== stateType || JSON.stringify(stateParams) !== JSON.stringify(params)) {
      this.getGoodsList(true);
    }
  }

  onNumChange = (pageNum) => {
    const { params } = this.state;
    this.setState({
      pageNum,
      params: Object.assign(params, { page_num: pageNum }),
    });
    this.getGoodsList();
    document.getElementById('root').scrollIntoView(true);
  }

  // 数据列表
  getGoodsList = async (afresh) => {
    const { url, params, type } = this.props;
    this.setState({
      url,
      params,
      type,
    });
    if (afresh) {
      this.setState({
        pageNum: 1,
      });
      params.page_num = 1;
    }
    const obj = JSON.parse(JSON.stringify(params));
    delete obj.firstId;
    delete obj.name;
    const res = await ajax(url, { ...obj }, 'get');
    if (res.status) {
      let id; let goodsList; let name; let total; let catList;
      if (!WHITEARRAY.includes(type)) {
        if (res.returndata.catList.length > 0) {
          id = '';
          name = '';
          catList = res.returndata.catList;
          goodsList = res.returndata.data;
        } else {
          id = params.firstId;
          goodsList = res.returndata.data;
          name = params.name;
          catList = [];
        }
        total = res.returndata.total_count;
      } else {
        id = res.returndata.id;
        goodsList = res.returndata.goodsList;
        name = res.returndata.name;
        total = res.returndata.total_count;
        catList = [];
      }
      this.setState({
        goodsList,
        id: String(id),
        name,
        total,
        catList,
      });
    }
  }

  render() {
    const {
      goodsList, id, name, pageNum, total, url, catList,
    } = this.state;
    console.log(url);
    return (
      <div className={style.pcStoreDisplayList}>
        <GoodClassify fid={id} name={name} catList={catList} />
        <div>
          <GoodList goodsList={goodsList} />
          {!!total && (
            <PagePaginaton
              onChange={this.onNumChange}
              defaultPageSize={12}
              current={pageNum}
              total={total}
            />
          )}
        </div>
      </div>
    );
  }
}

GoodDisplayList.defaultProps = {
  params: {},
};

GoodDisplayList.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.object,
  type: PropTypes.string.isRequired,
};

export default GoodDisplayList;
