function padLeftZero(str) {
  return (`00${str}`).substr(str.length);
}

/**
 * 格式化输出时间
 * @param {*} date 时间对象
 * @param {*} fmt 输出格式
 */
export function formatData(date, fmt) {
  let fmt2 = fmt;
  if ((/(y+)/).test(fmt2)) {
    fmt2 = fmt2.replace(RegExp.$1, (date.getFullYear())).substr(4 - RegExp.$1.length);
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
  };
  const keys = Object.keys(o);
  keys.forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt2)) {
      const str = `${o[k]}`;
      fmt2 = fmt2.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  });
  // console.log(date, fmt2);
  return fmt2;
}

/**
 * 格式化输出时间戳
 * @param {*} dateStr 时间戳字符串
 * @param {*} fmt 输出格式
 */
export function formatDataStr(dateStr, fmt) {
  const datenewStr = String(dateStr);
  const timeNew = datenewStr.length === 10 ? `${datenewStr}000` : datenewStr;
  const timeNum = parseInt(timeNew, 10);
  const date = new Date(timeNum);
  return formatData(date, fmt);
}
